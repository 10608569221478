import { ITimeRangeSchema } from "@snackpass/snackpass-types";
import { appendBlock, createBlock } from "controllers/blockTree";
import {
    AppType,
    Block,
    BlockType,
    CaptionType,
    HorizontalAlignment,
    PageType,
    Profile,
    SnackpassStore,
    VerticalAlignment,
} from "types";
import { DEFAULT_BG_IMAGE } from "utilities/theme";
import { v4 as uuidv4 } from "uuid";
type StoreNameParts = {
    name: string;
    address: string;
};

function parseStoreName(storeName: string | undefined): StoreNameParts {
    if (!storeName) {
        return { name: "", address: "" };
    }
    const matched = storeName.match(/(.*?)(?:\((.*?)\))?$/);

    if (!matched) {
        return { name: storeName, address: "" };
    }

    const [, name, address] = matched;

    return {
        name: name.trim(),
        address: address ? address.trim() : "",
    };
}

export function getBlocks(store: SnackpassStore, profile: Profile) {
    // Page Block
    console.log("getBlocks", store);
    const page: Block = {
        id: uuidv4(),
        type: BlockType.page,
        parent: "",
        properties: {
            page_url: "",
        },
        profile: profile.id,
        archived: false,
    };
    const newBlocks: Block[] = [];
    appendBlock(newBlocks, page);

    // App Page Block for Online Ordering
    const appPage: Block = {
        id: uuidv4(),
        type: BlockType.page,
        parent: "",
        properties: {
            page_type: PageType.APP,
            app_type: AppType.OnlineOrdering,
            page_url: AppType.OnlineOrdering,
            page_name: "Order Online",
        },
        profile: profile.id,
        archived: false,
    };
    if (store.onlineOrderingEnabled) {
        // appendBlock(newBlocks, appPage);
    }

    // Gift Card Page Block
    const giftCardPage: Block = {
        id: uuidv4(),
        type: BlockType.page,
        parent: "",
        properties: {
            page_type: PageType.APP,
            app_type: AppType.GiftCards,
            page_url: AppType.GiftCards,
            page_name: "Gift Cards",
        },
        profile: profile.id,
        archived: false,
    };
    // appendBlock(newBlocks, giftCardPage);

    // Catering Page Block
    const cateringPage: Block = {
        id: uuidv4(),
        type: BlockType.page,
        parent: "",
        properties: {
            page_type: PageType.APP,
            app_type: AppType.Catering,
            page_url: AppType.Catering,
            page_name: "Catering",
        },
        profile: profile.id,
        archived: false,
    };

    if (store.catering.enabled) {
        // appendBlock(newBlocks, cateringPage);
    }

    const storeImage = store?.coverPhoto || store?.thumbnailUrl;
    // Media Block
    const mediaBlock: Block = {
        id: uuidv4(),
        type: BlockType.media,
        parent: page.id,
        properties: {
            media_list: [
                {
                    type: "image",
                    url: !storeImage ? DEFAULT_BG_IMAGE : storeImage,
                    id: uuidv4(),
                    active: true,
                    format: "png",
                },
            ],
            height_desktop: !storeImage ? 200 : 580,
            height_mobile: !storeImage ? 200 : 480,
            brightness: 100,
            caption_type: CaptionType.big,
            caption_title_text: parseStoreName(store.name).name,
            caption_subtitle_text:
                store.aboutUs || parseStoreName(store.name).address,
            alignment_horizontal: HorizontalAlignment.center,
            alignment_vertical: VerticalAlignment.center,
            full_width: true,
        },
        profile: profile.id,
        archived: false,
    };
    appendBlock(newBlocks, mediaBlock);
    const dailyHours = store.hours ? groupHoursByDays(store.hours.local) : [];
    const now = new Date();
    const currentDay = now.getDay();
    const openRanges = dailyHours[currentDay];
    const currentTime = now.getHours() * 60 + now.getMinutes();

    // Order Here Button
    const orderHereBlock: Block = {
        id: uuidv4(),
        type: BlockType.links,
        parent: page.id,
        properties: {
            links: [
                {
                    id: uuidv4(),
                    name: "Order Here",
                    url: `https://order.snackpass.co/$${store.id}`,
                    icon: "store",
                    active: true,
                },
            ],
            social_links: [], // Empty for now, but can be filled similarly
            padding_top: 8,
        },
        profile: profile.id,
        archived: false,
    };
    if (store.onlineOrderingEnabled) {
        appendBlock(newBlocks, orderHereBlock);
    }

    // todo: clean this up. right now this is in flux so not worth it
    const isOpen = openRanges.some(
        timeRange =>
            currentTime >= timeRange.start && currentTime <= timeRange.end,
    );
    // Text Block
    const otherDays = [
        ...Days.slice(currentDay + 1),
        ...Days.slice(0, currentDay),
    ];
    const other = otherDays.map((day, index) => {
        const actualIndex = (currentDay + 1 + index) % 7;
        return `${day}: ${dailyHours[actualIndex]
            .map(timeRange => formatTimeRange(timeRange))
            .join(", ")}`;
    });
    const textBlock: Block = {
        id: uuidv4(),
        type: BlockType.text,
        parent: page.id,
        properties: {
            text_body: `<div>
            <br/><br/><br/>
            <p>${isOpen ? "Open" : "Closed"} Now</p>
            <p> ${Days[currentDay]}: ${dailyHours[currentDay]
                .map(timeRange => formatTimeRange(timeRange))
                .join(", ")}</p>
            <br/>
            <p>All Hours</p>
            <p>${other.join("<br/>")}</p>
            <br/>
            <h3>${store.storeVerification?.name || ""}</h3>
            <p>${store.address}</p>
            <br/><br/><br/>
            <p/>
            </div>`,
            full_width: true,
        },
        profile: profile.id,
        archived: false,
    };
    appendBlock(newBlocks, textBlock);

    // Hours Block
    const hoursBlock: Block = {
        id: uuidv4(),
        type: BlockType.hours,
        parent: page.id,
        properties: {},
        profile: profile.id,
        archived: false,
    };
    // appendBlock(newBlocks, hoursBlock);

    // Address Block
    const addressBlock: Block = {
        id: uuidv4(),
        type: BlockType.address,
        parent: page.id,
        properties: {},
        profile: profile.id,
        archived: false,
    };
    // appendBlock(newBlocks, addressBlock);

    // Address Block
    const menuBlock: Block = {
        id: uuidv4(),
        type: BlockType.menu,
        parent: page.id,
        properties: {},
        profile: profile.id,
        archived: false,
    };
    // appendBlock(newBlocks, menuBlock);

    // TODO: add gift cards and catering.

    // Links Block
    const baseLinks = [
        {
            id: uuidv4(),
            name: "Phone",
            url: `tel:${store.phoneNumber}`,
            icon: "phone",
            active: true,
        },
    ];

    baseLinks.push({
        id: uuidv4(),
        name: "Privacy",
        url: "https://privacy.snackpass.co/",
        icon: "page",
        active: true,
    });

    baseLinks.push({
        id: uuidv4(),
        name: "Terms of Service",
        url: "https://legal.snackpass.co/",
        icon: "page",
        active: true,
    });

    const linksBlock: Block = {
        id: uuidv4(),
        type: BlockType.links,
        parent: page.id,
        properties: {
            links: baseLinks,
            social_links: [], // Empty for now, but can be filled similarly
            padding_top: 8,
        },
        profile: profile.id,
        archived: false,
    };
    appendBlock(newBlocks, linksBlock);

    return newBlocks;
}

const groupHoursByDays = (timeRanges: ITimeRangeSchema[]) => {
    const dailyHours: ITimeRangeSchema[][] = Array.from(
        { length: 7 },
        () => [],
    );
    const minutesInADay = 60 * 24;

    timeRanges.forEach(timeRange => {
        const startDay = Math.floor(timeRange.start / minutesInADay);
        const endDay = Math.floor(timeRange.end / minutesInADay);

        for (let day = startDay; day <= endDay; day++) {
            dailyHours[day].push({
                start: Math.max(timeRange.start - day * minutesInADay, 0),
                end: Math.min(
                    timeRange.end - day * minutesInADay,
                    minutesInADay - 1,
                ),
            });
        }
    });

    return dailyHours;
};

const formatTimeRange = (timeRange: ITimeRangeSchema) => {
    return `${formatTime(timeRange.start)} - ${formatTime(timeRange.end)}`;
};

const formatTime = (time: number): string => {
    const minutes = time % 60;
    const hours = (time - minutes) / 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const meridian = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours > 12 ? hours - 12 : hours;
    return `${formattedHours}:${formattedMinutes} ${meridian}`;
};
const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
